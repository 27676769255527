import React, { Component } from 'react'
import MediaQuery from 'react-responsive';
import MouseScroll from 'components/mouse-srcoll/mouse-scroll';
import $ from 'jquery'
import OwlCarousel from "react-owl-carousel2"
const signal_left = require('../../../assets/images/Inicio/iconos/signal-left.svg').default;
const signal_right = require('../../../assets/images/Inicio/iconos/signal-right.svg').default;

const options = {
    items: 1,
    rewind: true,
    loop:true,
    nav:false,
    center:true,
    autoplayTimeout:7000,
    smartSpeed:1000,
    mouseDrag: true,
    touchDrag: true,
    autoplay:true,
    navText:[
        `<div class="signal-left"><img src='${signal_left}'/></div>`,
        `<div class="signal-left"><img src='${signal_right}'/></div>`
    ],
    pullDrag: true,
};
const events = {
    onChanged: function(e){
        console.log(e)
        $(`.item.ball`).removeClass('active')
        $(`.ball-${e.page.index}`).addClass('active')
    },
};

export default class Paginadeinicio extends Component {
    constructor(props){
        super()
        this.state = {
            imagenes:[
                {img:require("../../../assets/images/Inicio/25-01-23/slider-wed-en-construccion.jpg"),movil:require("../../../assets/images/Inicio/25-01-23/slider-mobile-en-construccion.jpg")},
                {img:require("../../../assets/images/Inicio/29-05-2023/2d_desktop.png"),movil:require("../../../assets/images/Inicio/29-05-2023/2d_mobile.png")},
                {img:require("../../../assets/images/Inicio/29-05-2023/3d_desktop.png"),movil:require("../../../assets/images/Inicio/29-05-2023/3d_mobile.png")},
                {img:require("../../../assets/images/Inicio/junio-2022/slider-sala de-ventas-desktop.png"),movil:require("../../../assets/images/Inicio/junio-2022/slider-sala-de-ventas-mobile.png")},
                {img:require("../../../assets/images/Inicio/Muvin_dosktop_v2.png"),movil:require("../../../assets/images/Inicio/sala_de_venta_movil.png")},
            ]
        }
    }
    componentDidMount(){
        window.scrollTo(0,0)
        $(".move-more-info").click(function(){
            $('html, body').animate({
                scrollTop: $(".formulario").offset().top-100
            }, 700);
        })
    }
    render() {
        return (
            <div className={"Inicio-two "} id="inicio">
            <div className="Inicio-background">
            <div className="landing-1">
                <MediaQuery query="(min-width: 740px)">
                    <OwlCarousel ref="car" options={options} events={events} className="owl-carousel MyCarouselHorizontal2 slider-disfruta owl-theme owl-loaded owl-drag">
                        {
                            this.state.imagenes.map((e,index)=>{
                                return(
                                    <div className='landing-1'>
                                        
                                        {/* {
                                            index === 0 ? <a className='link-slider' href='https://cyberdepadays.cosapiinmobiliaria.com.pe/'></a> : null
                                        } */}
                                        <img className='background1' src={e.img} ></img>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </MediaQuery>
                <MediaQuery query="(max-width:740px)">
                    <OwlCarousel ref="car" options={options} events={events} className="owl-carousel MyCarouselHorizontal2 slider-disfruta owl-theme owl-loaded owl-drag">
                        {
                            this.state.imagenes.map((e,index)=>{
                                return(
                                    <div className='landing-1'>
                                        {/* {
                                            index === 0 ? <a className='link-slider' href='https://summerdays.cosapiinmobiliaria.com.pe/' target={"_blank"}></a> : null
                                        } */}
                                         {
                                            index === 3 ? <a className='link-slider' href='/avance-de-obra'></a> : null
                                        }
                                        <img className='background1' src={e.movil} ></img>
                                    </div>
                                )
                            })
                        }
                    </OwlCarousel>
                </MediaQuery>
            </div>
            <div className="signal movil">
                <div className="flechas flecha-left" onClick={() => this.refs.car.prev()} >
                    <div class="signal-left">
                        <img src={signal_left}/>
                    </div>
                    <div className="after"></div>
                </div>
                <div className="flechas flecha-right"  onClick={() => this.refs.car.next()}>
                    <div class="signal-left">
                        <img src={signal_right}/>
                    </div>
                <div className="after"></div></div>
            </div>
            <div className="landing-footer">
                <div className="items contacto">
                </div>
                <div className="items mouse">
                    <MouseScroll></MouseScroll>
                </div>
                <div className="items counter">
                    <div className="signal">
                        <div className="flechas flecha-left" onClick={() => this.refs.car.prev()}>
                            <div class="signal-left">
                                <img src={signal_left}/>
                            </div>
                        </div>
                        <div className="flechas flecha-right"  onClick={() => this.refs.car.next()}>
                            <div class="signal-left">
                                <img src={signal_right}/>
                            </div>
                        </div>
                    </div>
                    <div className="indicador">
                        {
                             this.state.imagenes.map((e,index)=>{
                                 return(
                                    <div className={`item ball ball-${index}`}>
                                    </div>
                                 )
                             })
                        }
                    </div>
                </div>
                </div>
            </div>
           
        </div>
        )
    }
}
